// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/background-login2.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".background-img[data-v-65112154]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}.size-card[data-v-65112154]{max-width:680px;min-height:386px;background-color:#fff!important}.logo2[data-v-65112154],.size-card[data-v-65112154]{margin-left:auto;margin-right:auto}.logo2[data-v-65112154]{width:100%;max-width:600px;max-height:600px;z-index:2}.x1[data-v-65112154]{margin-left:auto;margin-right:auto;margin-bottom:24px}", ""]);
// Exports
module.exports = exports;
