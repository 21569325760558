<template>
  <v-container
    fluid
    fill-height
    class="background-img"
  >
    <v-row>
      <v-col
        class="align-self-center"
        cols="6"
      >
        <v-img
          class="logo2"
          src="../assets/cot_logo2.png"
        >
        </v-img>
      </v-col>
      <v-col class="align-self-center">
        <v-card class="elevation-12 size-card px-6">
          <v-card-title>{{$t('t.ChangePassword')}}</v-card-title>
          <v-card-text>
            <p><span v-if="$route.query.error">{{$route.query.error}}. </span>{{$t('t.EnterPassword')}}</p>
            <v-form
              v-model="validForm"
              ref="changePasswordForm"
            >
              <v-text-field
                :label="$t('t.Password')"
                v-model="password"
                :prepend-icon="$icon('i.Lock')"
                :type="passwordShownAsPassword ? 'password' : 'text'"
                :rules="[rules.regex]"
                @keypress="validate"
                @update:error="invalidPassword = $event"
                @input="refreshErrors"
              >
                <template v-slot:append>
                  <v-btn
                    icon
                    @click="passwordShownAsPassword = !passwordShownAsPassword"
                  >
                    <v-icon>{{$icon(passwordShownAsPassword ? 'i.EyeOff' : 'i.Eye')}}</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <v-text-field
                :label="$t('t.ConfirmPassword')"
                v-model="confirmPassword"
                :prepend-icon="$icon('i.Lock')"
                :type="confirmPasswordShownAsPassword ? 'password' : 'text'"
                :rules="[rules.confirmeRule]"
                @keypress="validate"
                @input="refreshErrors"
              >
                <template v-slot:append>
                  <v-btn
                    icon
                    @click="confirmPasswordShownAsPassword = !confirmPasswordShownAsPassword"
                  >
                    <v-icon>{{$icon(confirmPasswordShownAsPassword ? 'i.EyeOff' : 'i.Eye')}}</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-form>
            <div
              v-if="!validForm && password !== confirmPassword"
              class="error--text"
            >{{$t('t.ConfirmationPasswordMismatch')}}</div>
            <div
              v-if="!validForm && (!password || invalidPassword)"
              class="error--text"
            >{{description}}</div>
            <div v-else>{{description}}</div>
          </v-card-text>
          <v-card-actions class="pt-2 pl-16 pr-16">
            <v-btn
              class="x1"
              large
              rounded
              block
              justify-center
              color="primary"
              @click="updatePassword"
              :loading="isWorking"
              v-ripple
              :disabled="!validForm || !password.length"
            >
              <span>{{$t('t.Save')}}</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="showError"
      color="error"
      :timeout="timeout"
    >{{ errorMessage }}</v-snackbar>
  </v-container>
</template>

<script>
import themeController from '@/themeController'

export default {
  data () {
    return {
      timeout: 6000,
      errorMessage: null,
      showError: false,
      isWorking: false,
      password: '',
      confirmPassword: '',
      passwordRecoveryMode: false,
      passwordShownAsPassword: true,
      confirmPasswordShownAsPassword: true,
      regexPattern: undefined,
      description: '',
      invalidPassword: false,
      validForm: false,
      rules: {
        regex: value => {
          if (!this.regexPattern) {
            return true
          }

          return new RegExp(this.regexPattern).test(value)
        },
        confirmeRule: value => {
          return value === this.password
        }
      }
    }
  },
  methods: {
    async updatePassword () {
      this.isWorking = true
      try {
        await this.$http().post(`/core/authentication/password/change/${this.$route.params.hash}`, {
          Hash: this.$route.params.hash,
          NewPass: this.password,
          PassConfirm: this.confirmPassword
        })
        this.$router.push('/login')
      } catch (e) {
        if (e.response.status === 404) {
          this.errorMessage = this.$t('t.PasswordResetLinkExpired')
          this.showError = true
        } else if (e.response.status === 406) {
          this.errorMessage = this.$t(e.response.data.message)
          this.showError = true
        } else {
          this.errorMessage = this.$t('t.ErrorChangingPass')
          this.showError = true
        }
      } finally {
        this.isWorking = false
      }
    },
    validate (e) {
      if (e.key === 'Enter' && this.validForm && this.password.length > 0) {
        this.updatePassword()
      }
    },
    refreshErrors () {
      this.$refs.changePasswordForm.validate()
    }
  },
  async mounted () {
    await themeController.loadDefaultTheme().catch(() => { })
    this.$vuetify.theme.isDark = false
    this.$vuetify.theme.themes.light.primary = '#ffffff'
    this.$vuetify.theme.themes.light.primary = themeController.primary
    const changePassword = await this.$http().get(`core/authentication/password/change/${this.$route.params.hash}`)
    if (changePassword.status !== 200) {
      this.errorMessage = this.$t('t.InternalError')
      this.showError = true
      return
    }
    this.regexPattern = changePassword.data.regexPattern
    this.description = changePassword.data.description
  }
}
</script>

<style lang="stylus" scoped>
.background-img
  background-image url('../assets/background-login2.png')
  background-size cover

.size-card
  max-width 680px
  min-height 386px
  margin-left auto
  margin-right auto
  background-color #fff !important

.logo2
  width 100%
  max-width 600px
  max-height 600px
  margin-left auto
  margin-right auto
  z-index 2

.x1
  margin-left auto
  margin-right auto
  margin-bottom 24px
</style>
